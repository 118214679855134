import { debounce } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { BiPlus } from "react-icons/bi";
import { useNavigate } from "react-router";

import {
  Breadcrumbs,
  Button,
  CustomSelect,
  SearchInput,
  Table,
  TitleText,
} from "components";
import { useCollateralQuery } from "hooks/collateralHandover";
import moment from "moment";
import { BsEye } from "react-icons/bs";

const statusArray = [
  {
    value: "status-1",
    label: "Status 1",
  },
  {
    value: "status-2",
    label: "Status 2",
  },
  {
    value: "status-3",
    label: "Status 3",
  },
  {
    value: "status-4",
    label: "Status 4",
  },
  {
    value: "status-5",
    label: "Status 5",
  },
];

const links = [
  {
    label: "Daftar Serah Terima Agunan",
  },
];

const StatusBadge = ({ status }) => {
  let defaultWrapperClass = "bg-warning-50 text-warning-700";

  switch (status) {
    case "Permintaan Serah Terima":
      defaultWrapperClass = "bg-primary-50 text-primary-700";
      break;
    case "Rusak":
      defaultWrapperClass = "bg-error-50 text-error-700";
      break;
    case "PUPN":
      defaultWrapperClass = "bg-purple-50 text-purple-700";
      break;
    case "Sudah Tidak Ada":
      defaultWrapperClass = "bg-warning-50 text-warning-700";
      break;
    default:
      defaultWrapperClass = "bg-warning-50 text-warning-700";
      break;
  }
  return (
    <div
      className={`badge border-transparent p-2 text-xs ${defaultWrapperClass}`}
    >
      {status}
    </div>
  );
};

const CollateralHandover = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState(undefined);
  const [statusFilter, setStatusFilter] = useState(undefined);

  const { data, isFetching } = useCollateralQuery([
    "handovers",
    { page, limit, status: statusFilter, search: keyword },
  ]);

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) {
            return event?.target?.value;
          }
          return undefined;
        }),
      []
    ),
    800
  );

  const onChangePage = useCallback((val) => setPage(val), []);
  const onChangeRowsPerPage = useCallback((val) => setLimit(val), []);
  const onChangeStatus = useCallback(
    (obj) => setStatusFilter(obj?.value ?? undefined),
    []
  );

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        render: (value, data, index) => {
          const no =
            parseInt(page) * parseInt(limit) - parseInt(limit) + index + 1;
          return no + ".";
        },
      },
      {
        id: "nap",
        title: "Tanggal Pengajuan",
        dataIndex: "created_at",
        sortable: true,
        render: (value, data, index) => (
          <div className="min-w-[250px]">{moment(value).format("DD MMMM YYYY") ?? "-"}</div>
        ),
      },
      {
        id: "nap",
        title: "Nomor NAP",
        dataIndex: "nap_number",
        sortable: true,
        render: (value, data, index) => (
          <div className="min-w-[250px]">{value ?? "-"}</div>
        ),
      },
      {
        id: "debitur",
        title: "Nama Debitur",
        dataIndex: "name",
        sortable: true,
        render: (value, data, index) => (
          <div className="min-w-[150px]">{value ?? "-"}</div>
        ),
      },
      {
        id: "debitur",
        title: "Kategori",
        dataIndex: "category",
        sortable: true,
        render: (value, data, index) => (
          <div className="min-w-[150px]">{value ?? "-"}</div>
        ),
      },
      {
        id: "user_submitted",
        title: "User Yang Menyerahkan",
        dataIndex: "user_submitted",
        sortable: true,
        render: (value, data, index) => (
          <div className="min-w-[150px]">{value ?? "-"}</div>
        ),
      },
      {
        id: "action",
        title: "aksi",
        dataIndex: "value",
        sortable: true,
        render: (value, data, index) => (
          <div className="min-w-[150px]">
            {
              <BsEye
                className={`text-gray-600 cursor-pointer`}
                onClick={() => navigate(`/collateral/handover/detail/${data.id}`)}
              />
            }
          </div>
        ),
      },
      // {
      //   id: "payment",
      //   title: "Nilai Permohonan Pembiayaan",
      //   dataIndex: "value",
      //   sortable: true,
      //   render: (value, data, index) => (
      //     <div className="min-w-[150px]">
      //       {value
      //         ? new Intl.NumberFormat("id-ID", {
      //             style: "currency",
      //             currency: "IDR",
      //           }).format(value)
      //         : "-"}
      //     </div>
      //   ),
      // },
    ];
  }, [page, limit]);

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs items={links} />

      <div className="md:flex block items-center justify-between">
        <TitleText className="flex-1">Daftar Serah Terima Agunan</TitleText>
        <Button
          className="bg-primary-700 hover:bg-primary-600 text-white"
          startIcon={<BiPlus />}
          onClick={() => navigate(`/collateral/handover/new`)}
        >
          Rencana Penyerahan Agunan
        </Button>
      </div>

      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4">
            <Table
              bordered
              layout="fixed"
              className="mb-4"
              columns={tableColumns}
              dataSource={data?.result ?? []}
              isLoading={isFetching}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              pagination={data?.paginator}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollateralHandover;
